
  <template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation class="justify-center" >
      <v-btn :disabled="!valid" color="success" class="mr-9" @click="validate">
        تسجيل دخول
      </v-btn>
      <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">
        مستخدم جديد
      </v-btn>
     
    </v-form>
  </v-container>
</template>
    <script>
export default {
  data: () => ({
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    checkbox: false,
  }),

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
